import Socials from "../Partials/Socials";
import Logo from "../Partials/Logo";

function Header() {
  return (
    <>
    <div>
    <link rel="stylesheet" media="screen" type="text/css" href="static/css/mkt-style.css" />
      <div className="nav-background"></div>
      <div className="page-overlay"></div>
      <div className="nav-mobile d-xl-none">
        <ul className=" custom-nav key-nav_left">
          <li className=" home"><a href="home">Home</a></li>
          <li className=" nieuws "><a href="nieuws">Nieuws</a></li>
          <li className=" informatie "><a href="informatie">Informatie</a></li>
          <li className=" evenementen "><a href="evenementen">Evenementen</a></li>


        </ul>
        <ul className=" custom-nav key-nav_right">
          <li className=" teams "><a href="teams">Teams</a></li>
          <li className=" overons "><a href="overons">De Vereniging</a></li>
          <li className=" vrienden "><a href="vrienden">Vrienden</a></li>
        </ul>
        <div className="d-lg-none">
        <Socials/>
        </div>
      </div>
      <section className="navbar">
        <div className="container">
          <div className="nav-left-wrapper">
            <div className="nav-left text-left text-xl-center">
              <div className="d-inline-block d-xl-none">
              </div>
              <div className="d-none d-xl-inline-block">
                <ul className=" custom-nav key-nav_left">
                  <li className=" home"><a href="home">Home</a></li>
                  <li className=" nieuws "><a href="nieuws">Nieuws</a></li>
                  <li className=" informatie "><a href="informatie">Informatie</a></li>
                  <li className=" evenementen "><a href="evenementen">Evenementen</a></li>
                </ul>
              </div>
            </div>
          </div>
          
              <Logo />
           
          <div className="nav-right-wrapper">
            <div className="nav-right text-right text-xl-center">
              <div className="d-inline-block d-xl-none">
                <div className="nav-toggle">
                  <div className="bars">
                    <div className="bar">
                      <span></span>
                    </div>
                    <div className="bar">
                      <span></span>
                    </div>
                    <div className="bar">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-xl-inline-block">
                <ul className=" custom-nav key-nav_right">
                  <li className=" teams "><a href="teams">Teams</a></li>
                  <li className=" overons "><a href="overons">De Vereniging</a></li>
                  <li className=" vrienden "><a href="vrienden">Vrienden</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default Header;