import React, { useEffect } from 'react';
import $ from 'jquery';
//import '.././../public/static/css/mkt-style.css'; // Assuming you have some CSS for the body.show-nav


const MenuButton = () => {
  useEffect(() => {
    function toggleNav() {
      $("body").toggleClass("show-nav");
    }

    $(".nav-toggle, .navbar-toggler").on("touchstart click", function (e) {
      e.preventDefault();
      toggleNav();
    });

    // Cleanup event listeners on component unmount
    return () => {
      $(".nav-toggle, .navbar-toggler").off("touchstart click");
    };
  }, []);

  return (
    <>
    
    </>
  );
};

export default MenuButton;