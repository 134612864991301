import React from 'react';

import reportWebVitals from './reportWebVitals';
import ReactDOM from "react-dom/client";
import Content from './App/App';
import Header from './App/Header';
import Menubutton from './Partials/Menubutton';
import Sticky from './Partials/Stickyeffectcomponent';

 const header = ReactDOM.createRoot(document.getElementById('header'));
 header.render(
 <Header />
 );



 const content = ReactDOM.createRoot(document.getElementById('root'));
 content.render(
 <Content />
 );

 
 

 const menubutton = ReactDOM.createRoot(document.getElementById('menubutton'));
 menubutton.render(
 <Menubutton />
 );

 const sticky = ReactDOM.createRoot(document.getElementById('sticky'));
 sticky.render(
 <Sticky />
 );



 reportWebVitals();