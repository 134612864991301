import React, { useEffect } from 'react';
import $ from 'jquery';

const StickyEffectComponent = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scroll = $(window).scrollTop();
      if (scroll >= 1) {
        $("body").addClass("sticky");
      } else {
        $("body").removeClass("sticky");
      }

      if (scroll >= 128) {
        $("body").addClass("sticky-topbar");
      } else {
        $("body").removeClass("sticky-topbar");
      }
    };

    $(window).on("scroll load", handleScroll);

    // Cleanup event listeners on component unmount
    return () => {
      $(window).off("scroll load", handleScroll);
    };
  }, []);

  return (
   <></>

  );
};

export default StickyEffectComponent;
