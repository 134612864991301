function Notfound() {
    return (
        <div>
            <section id="" className="hero centered small">
                <div className="slider v2">
                    <div className="slide">
                        <div className="slide-bg"></div>
                        <div className="container">
                            <div className="vtable">
                                <div className="vcell">
                                    <div className="titles">
                                        <h1>404 Pagina niet gevonden</h1>
                                        <div className="text">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btns">
                            <a href="#locaties" target="_blank" rel="noreferrer" className="btn ">
                                404 Pagina niet gevonden
                                <i className="fa fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section className="text-blocks nobottompadding" id="">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <div className="titles">
                                <h3>Spijkenisse</h3>
                                <div className="maintitle">
                                    <p>The Hurricanes</p>

                                </div>


                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-8">

                            <div className="block">
                                <div className="content">
                                    <h3>Helaas is de opgevraagde pagina niet gevonden.</h3>
                                  
                                    <p>&nbsp;</p>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </section>

        </div>
    );
}

export default Notfound;