export const Socials = ({
    facebook: Facebook,
    instagram: Instagram,
}) => {
    return (
        <>
            <ul>
                <li><a href="https://www.facebook.com/profile.php?id=100063468526850" target="blank"><i className="fab fa-facebook-f"></i> </a></li>
                <li><a href="https://www.instagram.com/spijkenisse_hurricanes/" target="blank"><i className="fab fa-instagram"></i> </a></li>
          </ul>
        </>
    )
};

export default Socials;
