import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import NotFound from "../Pages/Notfound";

// Define a dynamic import function
const loadComponent = (componentName) => {
  return React.lazy(() => import(`../Pages/${componentName}`));
};

const App = () => {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await axios.get('https://mw.thehurricanes.nl/GetRoutes');
        setRoutes(response.data);
      } catch (error) {
        console.error('Error fetching routes:', error);
      }
    };
    fetchRoutes();
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map((route, index) => {
            const Component = loadComponent(route.componentName);
            return (
              <Route
                key={index}
                path={route.path}
                element={<Component />}
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;